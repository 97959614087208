@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap);
* {
  font-family: "Rubik", sans-serif !important;
}

html {
  scroll-behavior: smooth !important;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  overflow-x: hidden !important;
  width: 100vw !important;
}

ul {
  padding-left: 15px;
}

.blink {
  animation-duration: 700ms;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.swiper-pagination > .swiper-pagination-bullet {
  opacity: 1;
  border: black 1px;
  background-color: grey;
}
.swiper-pagination > .swiper-pagination-bullet-active {
  background-color: #c03a2a;
}

.marker-label {
  position: relative;
  top: -50px;
  min-width: 150px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px #000000;
  padding: 10px;
  display: block;
}

@keyframes blink {
  from {
    background-color: #c03a2a;
    color: white;
  }
  to {
    background-color: #c03a2a;
    color: white;
  }
  /* to {
        background-color: #efefef;
        color: #C03A2A;
    } */
}
.MuiButton-startIcon {
  margin: 0 !important;
}

